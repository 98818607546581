import React, { useState } from 'react';

interface HomeFormProps {
  onSubmit: (data: { email: string; password: string }) => void;
  loading: boolean;
  error: string | null;
}

const HomeForm: React.FC<HomeFormProps> = ({ onSubmit, loading, error }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({ email, password });
  };

  return (
    <div className="p-8 rounded-lg gap-5">
      <h1 className="text-4xl font-bold text-center">Sign In </h1>
      <p className="mt-4 text-center text-base">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>

      <form onSubmit={handleSubmit} className="mt-6 space-y-4">
        <div>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 pl-5"
            placeholder="mail@mail.com"
            required
          />
        </div>

        <div>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 pl-5"
            placeholder="Mot De Passe"
            required
          />
        </div>

        {/* Display error message */}
        {error && <div className='bg-[#c3bec045] w-full p-2 rounded-md'> 

        <p className="text-red-500 text-center font-semibold">{error}</p>
        </div>
        }

        <button
          type="submit"
          className="w-full bg-[#D5AD5D] text-white font-semibold py-2 rounded-md hover:bg-[#A67B24]"
          disabled={loading}
        >
          {loading ? 'Vérification...' : 'Vérifier'}
        </button>
      </form>
    </div>
  );
};

export default HomeForm;
