import React from 'react';
import { useLocation } from 'react-router-dom';
import success from '../assets/images/success.png';
import fail from '../assets/images/fail.png';
import wallpaper from '../assets/wallpaper.png';
import logo from '../assets/logo.png';

const Alert = () => {
  const location = useLocation();
  const isSuccess = location.pathname.includes('success'); 
  return (
    <div
      className="min-h-screen flex items-center justify-center flex-col gap-5 bg-cover bg-center py-5"
      style={{ backgroundImage: `url(${wallpaper})` }}
    >
      <img src={logo} alt="MyApp Logo" className="h-[5rem]" />
      <div className="m-9 p-8 rounded-lg gap-5 bg-black w-[70%] bg-opacity-10 backdrop-blur-md text-center">
        <img 
          src={isSuccess ? success : fail} 
          alt={isSuccess ? "Success" : "Failure"} 
          className="h-[7rem] my-5 mx-auto" 
        />
        <h1 className="text-4xl text-white font-bold text-center">
          {isSuccess ? "Paiement accepté" : "Paiement refusé"}
        </h1>
        <p className="mt-4 text-center text-base text-gray-600">
          {isSuccess 
            ? "Votre paiement a été traité avec succès."
            : "Il y a eu un problème avec votre paiement."}
        </p>
      </div>
    </div>
  );
};

export default Alert;
