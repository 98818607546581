import React from 'react';
import logo from '../assets/footerLogo.png';
import { FacebookIcon, InstagramIcon, TwitterIcon } from '../assets/svg';

const Footer: React.FC = () => {
  let getYear = () => new Date().getFullYear()

  return (
    <footer className="bg-[#003D5E] p-6 text-white">
      <div className="container mx-auto flex flex-col items-center gap-3">
        {/* Section 1: Logo */}
        <div className="flex flex-col items-center mb-6">
          <img src={logo} alt="My App Logo" className="h-[5rem] mb-4" />
        </div>

        {/* Line under Section 3 */}
        <hr className="border-white mb-6 w-[50%]" />

        {/* Section 2: Contact, Sitemap & Newsletter */}
        <div className="flex flex-col md:flex-row justify-between mb-6 w-full md:w-[80%]">
          {/* Contact Section */}
          <div className="flex-1 text-left pr-4 mb-4 md:mb-0">
            <h2 className="text-lg font-bold mb-2 text-[#D5AD5D]">Contactez-nous</h2>
            <p className=' max-w-[80%] md-w-full'>Adresse: Immeuble Yasmine، Rue Du Lac Ammar,
              Tunis 1053</p>
            <p>Téléphone: +216 71 96 221</p>
            <p>Email: contact@pyramidecourtage.com</p>
          </div>

          {/* Sitemap Section */}
          <div className="flex-1 text-left mb-4 md:mb-0">
            <h2 className="text-lg font-bold mb-2 text-[#D5AD5D]">Sitemap</h2>
            <ul className="grid grid-cols-2 gap-2">
              <li><a href="/" className="hover:underline">Accueil</a></li>
              <li><a href="https://pyramidecourtage.com/contact/" target="_blank" className="hover:underline">Contact</a></li>
              <li><a href="https://pyramidecourtage.com/assurances-professionnels/" target="_blank" className="hover:underline">Assurances Professionnels</a></li>
              <li><a href="https://pyramidecourtage.com/a-propos/" target="_blank" className="hover:underline">À propos</a></li>
              <li><a href="https://pyramidecourtage.com/assurance-particuliers/" target="_blank" className="hover:underline">Assurance Particuliers</a></li>
              <li><a href="https://pyramidecourtage.com/ou-nous-trouver/" target="_blank" className="hover:underline">Où nous trouver?</a></li>
            </ul>
          </div>

          {/* Newsletter Section */}
          <div className="flex-1 text-left mb-4 md:mb-0">
            <h2 className="text-lg font-bold mb-2 text-[#D5AD5D]">Newsletter</h2>
            <p className="mb-2">Inscrivez-vous pour recevoir les dernières nouvelles :</p>
            <div className="flex relative mb-4">
              <input
                type="email"
                placeholder="Votre email"
                className="p-2 rounded-md border border-white bg-[#003D5E] text-white placeholder-gray-300 w-full pr-[50%]"
              />
              <button className="bg-[#D5AD5D] text-white py-2 rounded-md hover:bg-[#A67B24] absolute right-0 top-0 w-[45%]">
                S'inscrire
              </button>
            </div>
          </div>

        </div>

        {/* Line under Section 2 */}
        <hr className="border-white mb-6 w-[80%]" />

        {/* Section 3: Links */}
        <div className="flex flex-col md:flex-row justify-between mb-6 w-full my-5 md:w-[80%]">
          <div className="flex flex-col md:flex-row md:gap-4">
            <a href="/privacy" className="hover:underline">Politique de confidentialité</a>
            <a href="/terms" className="hover:underline">Termes et conditions</a>
            <a href="/help" className="hover:underline">Aidez-moi</a>
            <a href="/partners" className="hover:underline">Partenaires</a>
          </div>
          <div className="flex flex-col items-start  md:flex-col md:items-end md:ml-4">
            <span className="mb-2 md:mb-0">Rejoignez notre communauté !</span>
            <div className="flex md:flex space-x-4 mt-2">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>

        {/* Line under Section 3 */}
        <hr className="border-white mb-6 w-[80%]" />

        {/* Bottom Copyright */}
        <div className="mt-6 text-center">
          <p>&copy; {getYear()} - Powered by Lezarts.Digital.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
