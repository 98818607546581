import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import DevisList from './pages/DevisList';
import Home from './pages/Home';
import Devis from './pages/DevisTable';
import Alert from './pages/Alert';
import { useAuth } from './context/AuthContext';
import { DevisProvider } from './context/DevisContext';

const App: React.FC = () => {
    const { isAuthenticated } = useAuth();

    return (
        <Router>
            <DevisProvider>
                <div className="flex flex-col min-h-screen">
                    <Navbar />
                    <Routes>
                        <Route
                            path="/paiment/:status"
                            element={isAuthenticated ? <Alert /> : <Navigate to="/" replace />}
                        />
                        <Route
                            path="/"
                            element={isAuthenticated ? <Navigate to="/devis" replace /> : <Home />}
                        />
                        <Route
                            path="/devis"
                            element={isAuthenticated ? <DevisList /> : <Navigate to="/" replace />}
                        />
                        <Route
                            path="/devis/:id"
                            element={isAuthenticated ? <Devis /> : <Navigate to="/" replace />}
                        />
                    </Routes>
                    <Footer />
                </div>
            </DevisProvider>
        </Router>
    );
};

export default App;
