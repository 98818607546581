export const SkeletonLoader: React.FC = () => (
    <div className="grid grid-cols-1 md:grid-cols-5 text-left bg-white p-4 gap-2 md:gap-0 animate-pulse">
        <div className="h-6 bg-gray-300 rounded-md w-3/4"></div>
        <div className="h-6 bg-gray-300 rounded-md w-3/4"></div>
        <div className="h-6 bg-gray-300 rounded-md w-3/4"></div>
        <div className="h-6 bg-gray-300 rounded-md w-3/4"></div>
        <div className="flex justify-center gap-2 mt-2 md:mt-0 md:col-span-1">
            <div className="h-8 bg-gray-300 rounded-md w-20"></div>
            <div className="h-8 bg-gray-300 rounded-md w-20"></div>
        </div>
    </div>
);