import React, { useState } from 'react';
import wallpaper from '../assets/wallpaper.png';
import logo from '../assets/logo.png';
import HomeForm from '../components/home/HomeForm';
import { Login } from '../services/auth';
import axios from 'axios';
import { useAuth } from '../context/AuthContext'; 

const Home: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { setAuthToken } = useAuth(); 

  const handleFormSubmit = async (data: { email: string; password: string }) => {
    setLoading(true);
    setError(null);
    try {
      const loginResponse = await Login(data);
      setAuthToken(loginResponse.token);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        setError(error.response?.data?.message || 'An error occurred. Please try again.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center flex-col gap-5 bg-cover bg-center py-5"
      style={{ backgroundImage: `url(${wallpaper})` }}
    >
      <img src={logo} alt="MyApp Logo" className="h-[5rem]" />
      <HomeForm onSubmit={handleFormSubmit} loading={loading} error={error} />
    </div>
  );
};

export default Home;
