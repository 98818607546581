import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HiMenuAlt3, HiX } from 'react-icons/hi';
import logo from "../assets/logo.png"
import { FacebookIcon, InstagramIcon, TwitterIcon } from '../assets/svg';

const Navbar: React.FC = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const location = useLocation();

    const isActive = (path: string) => location.pathname === path;

    return (
        <nav className="bg-[#FFFF] shadow-md p-6">
            <div className="container mx-auto flex justify-between md:justify-around items-center">
                {/* Logo */}
                <Link to="/">
                    <img src={logo} alt="MyApp Logo" className="h-8 custom-md:h-10" />
                </Link>

                {/* Centered Links - Hidden on small screens */}
                <div className="hidden md:flex space-x-8 text-center font-semibold text-sm custom-md:text-lg" >
                    <Link to="/" className={isActive('/') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Accueil</Link>
                    <Link to="https://pyramidecourtage.com/a-propos/" target="_blank" className={isActive('/A propos') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>A propos</Link>
                    <div className="relative" 
                        onClick={() => setDropdownOpen(true)}
                       >
                        <Link to="/" className={isActive('/Nos offres') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Nos offres</Link>
                        {dropdownOpen && (
                            <div onMouseLeave={() => setDropdownOpen(!true)} className="absolute left-0 bg-white shadow-lg mt-2 rounded-md">
                                <Link to="https://pyramidecourtage.com/assurances-professionnels/" target="_blank" className="block py-2 px-9 text-[#003D5E] hover:bg-[#D5AD5D] hover:text-white">Assurances Professionnels</Link>
                                <Link to="https://pyramidecourtage.com/assurance-particuliers/" target="_blank" className="block py-2 px-9 text-[#003D5E] hover:bg-[#D5AD5D] hover:text-white">Assurance Particuliers</Link>
                            </div>
                        )}
                    </div>
                    <Link to="https://pyramidecourtage.com/contact/" target="_blank" className={isActive('/Contact') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Contact</Link>
                    <Link to="https://pyramidecourtage.com/ou-nous-trouver/" target="_blank" className={isActive('/Devis en ligne') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Où nous trouver?</Link>
                </div>

                {/* Social Media Icons - Hidden on small screens */}
                <div className="hidden md:flex space-x-3 ">
                    <a href="https://facebook.com" target="_blank"  rel="noopener noreferrer">
                        <FacebookIcon />
                    </a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                        <InstagramIcon />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <TwitterIcon />
                    </a>
                </div>

                {/* Hamburger Menu - Visible on small screens */}
                <div className="md:hidden">
                    <button onClick={() => setMenuOpen(!menuOpen)} className="text-[#D5AD5D]">
                        {menuOpen ? <HiX size={28} /> : <HiMenuAlt3 size={28} />}
                    </button>
                </div>
            </div>

            {/* Mobile Menu - Visible when menuOpen is true */}
            {menuOpen && (
                <div className="md:hidden mt-4">
                    <div className="flex flex-col items-center space-y-4">
                        <Link to="/" className={isActive('/') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Accueil</Link>
                        <Link to="https://pyramidecourtage.com/a-propos/" target="_blank" className={isActive('/A propos') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>A propos</Link>
                        <div className="relative" onMouseEnter={() => setDropdownOpen(true)} onMouseLeave={() => setDropdownOpen(false)}>
                            <Link to="/" className={isActive('/Nos offres') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Nos offres</Link>
                            {dropdownOpen && (
                                <div className="absolute left-0 bg-white shadow-lg mt-2 rounded-md">
                                    <Link to="https://pyramidecourtage.com/assurance-particuliers/" target="_blank" className="block py-2 px-9 text-[#003D5E] hover:bg-[#D5AD5D] hover:text-white">Assurances Professionnels</Link>
                                    <Link to="https://pyramidecourtage.com/assurances-professionnels/" target="_blank" className="block py-2 px-9 text-[#003D5E] hover:bg-[#D5AD5D] hover:text-white">Assurance Particuliers</Link>
                                </div>
                            )}
                        </div>
                        <Link to="https://pyramidecourtage.com/contact/" target="_blank" className={isActive('/Contact') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Contact</Link>
                    <Link to="https://pyramidecourtage.com/ou-nous-trouver/" target="_blank" className={isActive('/Devis en ligne') ? "text-[#D5AD5D] underline" : "text-[#003D5E] hover:text-[#D5AD5D]"}>Où nous trouver?</Link>
                  <div className="flex space-x-4 mt-4">
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FacebookIcon />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <InstagramIcon />
                            </a>
                            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
                                <TwitterIcon />
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
