import React, { createContext, useContext, useState, useEffect } from 'react';
import { GetDevisList } from '../services/devis';
import axios from 'axios';
import { DevisItem } from '../services/devis';
import { useAuth } from './AuthContext';

interface DevisContextType {
    devisData: DevisItem[];
    loading: boolean;
    error: string | null;
}

const DevisContext = createContext<DevisContextType | undefined>(undefined);

export const DevisProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isAuthenticated } = useAuth(); 
    const [devisData, setDevisData] = useState<DevisItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchDevisData = async () => {
            if (!isAuthenticated) {
                setLoading(false); 
                return;
            }

            setLoading(true); 
            try {
                const response = await GetDevisList();
                setDevisData(response);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setError(error.response?.data?.message || 'An error occurred. Please try again.');
                } else {
                    setError('An unexpected error occurred.');
                }
            } finally {
                setLoading(false);
            }
        };

        fetchDevisData();
    }, [isAuthenticated]);

    return (
        <DevisContext.Provider value={{ devisData, loading, error }}>
            {children}
        </DevisContext.Provider>
    );
};

export const useDevis = () => {
    const context = useContext(DevisContext);
    if (!context) {
        throw new Error('useDevis must be used within a DevisProvider');
    }
    return context;
};
