import axiosInstance from "../lib/config"

export interface LoginRequest {
    email: string;
    password: string;
}


export interface AuthResponse {
    token: string;
    email: string;
}


export const Login = async (loginData: LoginRequest): Promise<AuthResponse> => {
    try {
        const response = await axiosInstance.post<AuthResponse>('auth/signin', loginData);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export const LogOut = async (): Promise<AuthResponse> => {
    try {
        const response = await axiosInstance.post<AuthResponse>('auth/logout');
        return response.data;
    } catch (error) {
        throw error;
    }
};
