import React, { createContext, useContext, useState, ReactNode } from 'react';
import { LogOut } from '../services/auth'; 

interface AuthContextType {
    isAuthenticated: boolean;
    setAuthToken: (token: string | null) => void;
    logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
        return !!localStorage.getItem('authToken'); 
    });

    const setAuthToken = (token: string | null) => {
        if (token) {
            localStorage.setItem('authToken', token);
            setIsAuthenticated(true);
        } else {
            localStorage.removeItem('authToken');
            setIsAuthenticated(false);
        }
    };

    const logout = async () => {
        try {
            await LogOut(); 
        } catch (error) {
            console.error('Failed to log out:', error);
        } finally {
            setAuthToken(null); 
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, setAuthToken, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
