import React, { useState } from 'react';
import { CiLogout } from "react-icons/ci";
import { useAuth } from '../context/AuthContext';

const LogoutAlert = () => {
    const [showAlert, setShowAlert] = useState(false);
    const { logout } = useAuth();

    const handleLogoutClick = () => {
        setShowAlert(true);
    };

    const handleConfirmLogout = () => {
        setShowAlert(false);
        logout()
    };

    const handleCancelLogout = () => {
        setShowAlert(false);
    };

    return (
        <div className="relative">
            <button
                onClick={handleLogoutClick}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
            >
                <CiLogout size={23} />
            </button>

            {showAlert && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded shadow-md w-80">
                        <p className="text-lg mb-4">Êtes-vous sûr de vouloir vous déconnecter ?</p>
                        <div className="flex justify-around">
                            <button
                                onClick={handleConfirmLogout}
                                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none"
                            >
                                Oui
                            </button>
                            <button
                                onClick={handleCancelLogout}
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none"
                            >
                                Non
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LogoutAlert;
