import axiosInstance from "../lib/config"


export interface DevisItem {
    id: string;
    num: string;
    created_at: string;
    type: string;
    amount: string;
}

export interface ID {
    id: string;
}


export interface AuthResponse {
    token: string;
    email: string;
}



export const GetDevisList = async (): Promise<DevisItem[]> => {
    try {
        const response = await axiosInstance.get('devis');
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const GetDevisById = async (data: { num: string }): Promise<DevisItem> => {
    try {
        const response = await axiosInstance.post('devis/show', {
            num: data.num,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const DownloadPdf = async (Id: ID): Promise<Blob> => {
    try {
        const response = await axiosInstance.get(`devis/download?num=${Id.id}`, {
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

