import axiosInstance from "../lib/config";

export interface DevisItem {
    id: string;
    num: string;
    created_at: string;
    type: string;
    amount: string;
}

export interface ID {
    id: string;
}

export interface AuthResponse {
    token: string;
    email: string;
}

export const RequestPaiment = async (id: string, test: boolean = false): Promise<string> => {
    try {
        const response = await axiosInstance.get(`/payment/request`, {
            params: { test: test ? 'true' : 'false', id: id },
            responseType: 'text' 
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};
