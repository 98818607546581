import React, { useEffect, useState } from 'react';
import { PdfIcon, DetailsIcon } from '../assets/svg';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import wallpaper from '../assets/wallpaper.png';
import { SkeletonLoader } from '../helpers/loader';
import { useDevis } from '../context/DevisContext'; 
import LogoutAlert from '../components/logout';
import { DownloadPdf } from '../services/devis';

const DevisList: React.FC = () => {
    const navigate = useNavigate();
    const { devisData, loading, error } = useDevis(); 
    const [loadingIds, setLoadingIds] = useState<string[]>([]);

    const handleNavigate = (Id: any) => {
        navigate(`/devis/${Id}`);
    };

    const handleDownload = async (id: string) => {
        setLoadingIds((prev) => [...prev, id]);

        try {
            const response = await DownloadPdf({ id });
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `devis-${id}.pdf`;
            link.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download PDF:', error);
            // Optionally, set an error state here
        } finally {
            setLoadingIds((prev) => prev.filter((loadingId) => loadingId !== id));
        }
    };

    return (
        <div
            className="min-h-screen flex items-center justify-center flex-col gap-5 bg-cover bg-center py-5"
            style={{ backgroundImage: `url(${wallpaper})` }}
        >
            <img src={logo} alt="MyApp Logo" className="h-[5rem]" />

            <div className="m-1 p-2 rounded-lg gap-5 bg-black bg-opacity-10 backdrop-blur-md md:p-8 md:m-9">
                <div className="p-8 rounded-lg bg-white w-full">
                    <h1 className="text-4xl font-bold text-center">Avis de règlement</h1>
                    <p className="mt-4 text-center text-base text-gray-600">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    </p>
                    <div className="my-9">
                        <div className="border-dashed border-t-2 border-gray-300 mb-4"></div>

                        {loading ? (
                            <>
                                {[...Array(3)].map((_, index) => (
                                    <SkeletonLoader key={index} />
                                ))}
                            </>
                        ) : devisData.length === 0 ? (
                            <p className="text-center text-gray-500">Aucun devis disponible.</p>
                        ) : (
                            <>
                                <div className="hidden md:grid md:grid-cols-5 text-left p-4 rounded-t-lg border-b-2 border-gray-300">
                                    <div className="font-bold text-[#D5AD5D]">Numéro de l’avis</div>
                                    <div className="font-bold text-[#D5AD5D]">Date</div>
                                    <div className="font-bold text-[#D5AD5D]">Type d'assurance</div>
                                    <div className="font-bold text-[#D5AD5D]">Montant de la prime</div>
                                </div>

                                {devisData.map((devis, index) => (
                                    <div key={index} className="grid grid-cols-1 md:grid-cols-5 text-left bg-white p-4 gap-2 md:gap-0">
                                        <div className="md:hidden font-bold text-[#D5AD5D]">Numéro de l’avis:</div>
                                        <div className="md:col-span-1 w-full">{devis.num}</div>

                                        <div className="md:hidden font-bold text-[#D5AD5D]">Date:</div>
                                        <div className="md:col-span-1 w-full">{devis.created_at.split('T')[0]}</div>

                                        <div className="md:hidden font-bold text-[#D5AD5D]">Type d'assurance:</div>
                                        <div className="md:col-span-1 w-full">{devis.type}</div>

                                        <div className="md:hidden font-bold text-[#D5AD5D]">Montant de la prime:</div>
                                        <div className="md:col-span-1 w-full">{devis.amount}</div>

                                        <div className="flex justify-center gap-2 mt-2 md:mt-0 md:col-span-1">
                                            <button
                                                className="gap-2 flex items-center text-[#003D5E] shadow p-2 rounded transition hover:bg-opacity-70"
                                                onClick={() => handleNavigate(devis.num)}
                                                disabled={loadingIds.includes(devis.num)}
                                            >
                                                Détails <DetailsIcon />
                                            </button>
                                            <button
                                                className="gap-2 flex items-center bg-[#FDF1DC] text-[#D5AD5D] shadow p-2 rounded transition hover:bg-opacity-70"
                                                onClick={() => handleDownload(devis.num)}
                                                disabled={loadingIds.includes(devis.num)}
                                            >
                                                PDF <PdfIcon />
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                <div className="border-dashed border-t-2 border-gray-300 mt-4"></div>
                            </>
                        )}
                    </div>

                    {error && <p className="text-red-500 text-center">{error}</p>}
                    <LogoutAlert />
                </div>
            </div>
        </div>
    );
};

export default DevisList;
