import React from 'react';

export const FacebookIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 46.896 46.895">
        <g id="Groupe_3216" data-name="Groupe 3216" transform="translate(-3380 4444.202)">
            <path id="Tracé_20552" data-name="Tracé 20552" d="M23.448,47.477h0A23.232,23.232,0,1,1,46.68,24.245,23.232,23.232,0,0,1,23.448,47.477Z" transform="translate(3380 -4445)" fill="none" stroke="#141613" stroke-miterlimit="10" stroke-width="0.432" />
            <path id="Tracé_20553" data-name="Tracé 20553" d="M20.309,36.7h5.014V24.139h3.5l.373-4.2H25.323V17.54c0-.991.2-1.382,1.158-1.382h2.714V11.794H25.723c-3.732,0-5.414,1.644-5.414,4.789v3.352H17.7v4.257h2.608Z" transform="translate(3380 -4445)" fill="#141613" />
        </g>
    </svg>
);

export const InstagramIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 46.896 46.895">
        <g id="Groupe_3215" data-name="Groupe 3215" transform="translate(-3446.434 4445)">
            <path id="Tracé_20554" data-name="Tracé 20554" d="M89.882,46.679h0a23.232,23.232,0,1,1,23.232-23.232A23.232,23.232,0,0,1,89.882,46.679Z" transform="translate(3380 -4445)" fill="none" stroke="#d5ad5d" stroke-miterlimit="10" stroke-width="0.432" />
            <path id="Tracé_20555" data-name="Tracé 20555" d="M89.882,14.188c3.016,0,3.373.011,4.564.066a6.233,6.233,0,0,1,2.1.389,3.742,3.742,0,0,1,2.144,2.143,6.287,6.287,0,0,1,.389,2.1c.054,1.19.065,1.548.065,4.563s-.011,3.373-.065,4.564a6.287,6.287,0,0,1-.389,2.1,3.742,3.742,0,0,1-2.144,2.143,6.233,6.233,0,0,1-2.1.389c-1.191.054-1.548.066-4.564.066s-3.373-.012-4.564-.066a6.233,6.233,0,0,1-2.1-.389,3.742,3.742,0,0,1-2.144-2.143,6.287,6.287,0,0,1-.389-2.1c-.054-1.191-.065-1.548-.065-4.564s.011-3.373.065-4.563a6.287,6.287,0,0,1,.389-2.1,3.742,3.742,0,0,1,2.144-2.143,6.233,6.233,0,0,1,2.1-.389c1.191-.055,1.548-.066,4.564-.066m0-2.035c-3.067,0-3.452.013-4.657.068a8.293,8.293,0,0,0-2.741.525,5.773,5.773,0,0,0-3.3,3.3,8.254,8.254,0,0,0-.525,2.742c-.055,1.2-.068,1.589-.068,4.656s.013,3.453.068,4.657a8.254,8.254,0,0,0,.525,2.742,5.773,5.773,0,0,0,3.3,3.3,8.293,8.293,0,0,0,2.741.525c1.205.055,1.59.068,4.657.068s3.452-.013,4.657-.068a8.293,8.293,0,0,0,2.741-.525,5.773,5.773,0,0,0,3.3-3.3,8.277,8.277,0,0,0,.526-2.742c.054-1.2.067-1.589.067-4.657s-.013-3.451-.067-4.656a8.277,8.277,0,0,0-.526-2.742,5.773,5.773,0,0,0-3.3-3.3,8.293,8.293,0,0,0-2.741-.525c-1.205-.055-1.59-.068-4.657-.068" transform="translate(3380 -4445)" fill="#d5ad5d" />
            <path id="Tracé_20556" data-name="Tracé 20556" d="M89.882,17.647a5.8,5.8,0,1,0,5.8,5.8,5.8,5.8,0,0,0-5.8-5.8m0,9.565a3.765,3.765,0,1,1,3.765-3.765,3.765,3.765,0,0,1-3.765,3.765" transform="translate(3380 -4445)" fill="#d5ad5d" />
            <path id="Tracé_20557" data-name="Tracé 20557" d="M97.266,17.419a1.355,1.355,0,1,1-1.355-1.355,1.355,1.355,0,0,1,1.355,1.355" transform="translate(3380 -4445)" fill="#d5ad5d" />
        </g>
    </svg>
);

export const TwitterIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 46.897 46.895">
        <g id="Groupe_3214" data-name="Groupe 3214" transform="translate(-3512.867 4445)">
            <path id="Tracé_20550" data-name="Tracé 20550" d="M156.316,46.679h0a23.232,23.232,0,1,1,23.232-23.232A23.232,23.232,0,0,1,156.316,46.679Z" transform="translate(3380 -4445)" fill="none" stroke="#141613" stroke-miterlimit="10" stroke-width="0.432" />
            <path id="Tracé_20551" data-name="Tracé 20551" d="M145.34,31.282a12.807,12.807,0,0,0,19.7-11.373,9.106,9.106,0,0,0,2.247-2.331,9.022,9.022,0,0,1-2.586.709,4.511,4.511,0,0,0,1.98-2.492,9.018,9.018,0,0,1-2.859,1.094A4.507,4.507,0,0,0,156.15,21a12.775,12.775,0,0,1-9.281-4.7,4.509,4.509,0,0,0,1.393,6.013,4.5,4.5,0,0,1-2.04-.564,4.508,4.508,0,0,0,3.612,4.473,4.5,4.5,0,0,1-2.033.077,4.5,4.5,0,0,0,4.206,3.127,9.049,9.049,0,0,1-6.667,1.865" transform="translate(3380 -4445)" fill="#141613" />
        </g>
    </svg>
);

export const DetailsIcon: React.FC = () => (
    <svg id="Groupe_3244" data-name="Groupe 3244" xmlns="http://www.w3.org/2000/svg" width="21.051" height="21.046" viewBox="0 0 21.051 21.046">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_533" data-name="Rectangle 533" width="21.051" height="21.046" fill="#003d5e" />
            </clipPath>
        </defs>
        <g id="Groupe_3243" data-name="Groupe 3243" clip-path="url(#clip-path)">
            <path id="Tracé_20592" data-name="Tracé 20592" d="M58.59,72.813h3.761a1.463,1.463,0,0,1,.224.01.407.407,0,0,1,.341.379.4.4,0,0,1-.308.4,1.03,1.03,0,0,1-.242.027q-3.781,0-7.562,0a1,1,0,0,1-.242-.028.4.4,0,0,1,.043-.779,3.8,3.8,0,0,1,.429-.01H58.59" transform="translate(-52.039 -69.834)" fill="#003d5e" />
            <path id="Tracé_20593" data-name="Tracé 20593" d="M100.292,142.833q-1.491,0-2.981,0c-.291,0-.459-.13-.482-.361a.389.389,0,0,1,.29-.431.914.914,0,0,1,.283-.025h5.758a.915.915,0,0,1,.283.025.4.4,0,0,1-.049.785,1.357,1.357,0,0,1-.2.008h-2.9" transform="translate(-92.866 -136.205)" fill="#003d5e" />
            <path id="Tracé_20594" data-name="Tracé 20594" d="M56.669,212.166c-.626,0-1.252,0-1.877,0-.361,0-.565-.25-.459-.543a.358.358,0,0,1,.291-.253,1.744,1.744,0,0,1,.263-.021q1.786,0,3.571,0c.388,0,.585.142.58.417s-.195.4-.573.4h-1.8" transform="translate(-52.083 -202.701)" fill="#003d5e" />
            <path id="Tracé_20595" data-name="Tracé 20595" d="M56.485,280.737H58.3a.4.4,0,0,1,.411.274.38.38,0,0,1-.108.428.467.467,0,0,1-.339.115c-1.184,0-2.368,0-3.552,0a.425.425,0,0,1-.469-.407c0-.241.19-.407.485-.408.585,0,1.17,0,1.756,0" transform="translate(-52.025 -269.252)" fill="#003d5e" />
            <path id="Tracé_20596" data-name="Tracé 20596" d="M56.462,420.087c-.552,0-1.1,0-1.655,0-.352,0-.548-.243-.452-.533a.36.36,0,0,1,.3-.265,1.72,1.72,0,0,1,.243-.019q1.563,0,3.125,0c.386,0,.588.146.582.417s-.2.4-.575.4H56.462" transform="translate(-52.108 -402.117)" fill="#003d5e" />
            <path id="Tracé_20597" data-name="Tracé 20597" d="M55.059,142.5a.41.41,0,0,1-.4.408.416.416,0,0,1-.416-.411.426.426,0,0,1,.409-.4.417.417,0,0,1,.4.4" transform="translate(-52.029 -136.285)" fill="#003d5e" />
            <path id="Tracé_20598" data-name="Tracé 20598" d="M180.873,419.868a.407.407,0,1,1-.41-.393.411.411,0,0,1,.41.393" transform="translate(-172.693 -402.315)" fill="#003d5e" />
            <path id="Tracé_20599" data-name="Tracé 20599" d="M229.2,225.533a3.6,3.6,0,1,1-3.588-3.608,3.61,3.61,0,0,1,3.588,3.608m-.818-.006A2.78,2.78,0,1,0,225.6,228.3a2.789,2.789,0,0,0,2.784-2.776" transform="translate(-212.927 -212.847)" fill="#003d5e" />
            <path id="Tracé_20600" data-name="Tracé 20600" d="M300.493,264.97a1.852,1.852,0,0,1,1.783,1.782.421.421,0,0,1-.37.459.394.394,0,0,1-.438-.387,1.072,1.072,0,0,0-1.045-1.046.4.4,0,0,1-.389-.436.421.421,0,0,1,.458-.372" transform="translate(-287.76 -254.13)" fill="#003d5e" />
            <path id="Tracé_20601" data-name="Tracé 20601" d="M20.645,18.682l-.186-.186c-.036-.038-.072-.076-.11-.113l-.735-.732-.734-.733q-.691-.691-1.384-1.38l-.086-.085a.2.2,0,0,1-.04-.29,5.272,5.272,0,0,0-.657-5.93.732.732,0,0,1-.195-.526c.008-1.376,0-2.753.007-4.13a.735.735,0,0,0-.229-.563Q14.4,2.127,12.508.228A1.255,1.255,0,0,0,12.189,0H.985A1.8,1.8,0,0,0,.31.419a1.3,1.3,0,0,0-.3.763c0,.081,0,.163,0,.245q0,9.032,0,18.064a1.3,1.3,0,0,0,.91,1.4c.015,0,.023.029.034.044H15.5a1.534,1.534,0,0,0,.732-.441,1.314,1.314,0,0,0,.29-.851c0-.371,0-.743,0-1.115l.031-.022.125.125.016.018c.254.256.511.511.767.765l.192.191q.287.287.57.578c.092.1.185.188.283.274l.18.18a1.385,1.385,0,1,0,1.958-1.958m-3.468-6.014a4.45,4.45,0,0,1-.785,2.539,4.557,4.557,0,0,1-1.179,1.178,4.493,4.493,0,1,1,1.964-3.717M12.517,1.452,15.07,4c-.714,0-1.438.007-2.162,0a.387.387,0,0,1-.388-.418c-.007-.712,0-1.424,0-2.131m3.194,16.553c-.019.531,0,1.063-.006,1.594,0,.372-.148.519-.522.519H1.343c-.373,0-.522-.148-.522-.52q0-9.127,0-18.255c0-.384.146-.526.536-.526q5.079,0,10.159,0c.16,0,.186.046.184.192-.007.832,0,1.663,0,2.494a1.239,1.239,0,0,0,1.329,1.325c.831,0,1.662,0,2.494,0,.155,0,.189.038.187.189-.007,1.036,0,2.071,0,3.107V8.3a6.134,6.134,0,0,0-1.25-.636A5.315,5.315,0,0,0,8.17,9.878a5.213,5.213,0,0,0-.6,4.255c.046.166.011.185-.145.185-1.506-.005-3.012,0-4.517,0H2.749c-.33,0-.529.157-.53.411s.2.407.529.407q2.535,0,5.07,0a.213.213,0,0,1,.22.124,5.236,5.236,0,0,0,3.889,2.682,5.064,5.064,0,0,0,3.221-.559.235.235,0,0,1,.326.042c.015.013.029.027.042.04s.038.045.059.067a.687.687,0,0,1,.137.48m4.113,2.04a.543.543,0,0,1-.6-.032,1.011,1.011,0,0,1-.151-.137c-.978-.978-1.954-1.959-2.937-2.931-.116-.115-.1-.168.013-.26a3.64,3.64,0,0,0,.562-.563c.067-.082.109-.109.2-.017q1.505,1.516,3.019,3.022a.613.613,0,0,1,.188.432.561.561,0,0,1-.3.486" transform="translate(0)" fill="#003d5e" />
            <path id="Tracé_20602" data-name="Tracé 20602" d="M429.094,381.22q-.691-.691-1.384-1.38c.023.02.045.04.067.062Z" transform="translate(-410.214 -364.302)" fill="#003d5e" />
        </g>
    </svg>

);


export const PdfIcon: React.FC = () => (
    <svg id="Groupe_3238" data-name="Groupe 3238" xmlns="http://www.w3.org/2000/svg" width="24.239" height="26.954" viewBox="0 0 24.239 26.954">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_530" data-name="Rectangle 530" width="24.239" height="26.954" fill="#d5ad5d" />
            </clipPath>
        </defs>
        <g id="Groupe_3237" data-name="Groupe 3237" clip-path="url(#clip-path)">
            <path id="Tracé_20570" data-name="Tracé 20570" d="M5.907,26.954c-.008-.014-.014-.037-.025-.041a1.621,1.621,0,0,1-1.146-1.758c.016-4.079,0-8.158.013-12.238,0-.245-.067-.3-.3-.3-.9.014-1.807.007-2.711.005A1.62,1.62,0,0,1,0,10.906q0-2.5,0-5A1.6,1.6,0,0,1,1.7,4.211c.939,0,1.877-.006,2.816,0,.188,0,.232-.051.229-.233-.01-.8-.007-1.6,0-2.395A1.512,1.512,0,0,1,6.33,0q5.777,0,11.554,0a1.53,1.53,0,0,1,1.131.469q2.371,2.374,4.746,4.745a1.58,1.58,0,0,1,.479,1.179q-.007,9.474,0,18.949a1.475,1.475,0,0,1-1.248,1.572c-.011,0-.016.026-.025.04ZM14.488,25.9h8.08c.465,0,.615-.148.615-.61q0-9.3.006-18.607c0-.223-.077-.252-.269-.25-1.281.007-2.562.006-3.842,0A1.166,1.166,0,0,1,17.8,5.164c0-1.29,0-2.579,0-3.869,0-.191-.037-.246-.24-.246q-5.606.011-11.212.006c-.394,0-.559.165-.56.559q0,1.013,0,2.026c0,.182-.052.411.037.533s.327.027.5.037a.52.52,0,0,1,.494.695.549.549,0,0,1-.581.358q-2.224,0-4.448,0c-.568,0-.744.173-.744.732q0,2.421,0,4.842c0,.553.187.739.744.739H11.93c.55,0,.739-.191.739-.748q0-1.855,0-3.711c0-.412.007-.825,0-1.237a.563.563,0,0,0-.624-.617c-.158,0-.317.009-.473-.007A.523.523,0,0,1,11.6,4.213c.114-.007.228,0,.342,0a1.624,1.624,0,0,1,1.779,1.778q0,2.421,0,4.842a1.636,1.636,0,0,1-1.795,1.8c-1.956,0-3.913,0-5.869-.006-.222,0-.268.057-.268.272Q5.8,19.078,5.8,25.262c0,.5.14.639.64.639h8.053M22.348,5.384,18.855,1.89c0,1.084,0,2.195,0,3.305,0,.134.049.19.183.19,1.111,0,2.221,0,3.311,0" transform="translate(0 0)" fill="#d5ad5d" />
            <path id="Tracé_20571" data-name="Tracé 20571" d="M130.108,306.475q2.577,0,5.154,0c.407,0,.631.178.647.5a.5.5,0,0,1-.429.541,1.669,1.669,0,0,1-.288.017H125.041a1.776,1.776,0,0,1-.288-.015.518.518,0,0,1,.017-1.026,2.471,2.471,0,0,1,.315-.013h5.023" transform="translate(-115.568 -284.914)" fill="#d5ad5d" />
            <path id="Tracé_20572" data-name="Tracé 20572" d="M130.122,269.29H135.2a1.439,1.439,0,0,1,.288.011.487.487,0,0,1,.42.441.5.5,0,0,1-.285.541.653.653,0,0,1-.306.061H124.927a.99.99,0,0,1-.131,0,.526.526,0,0,1-.478-.558.5.5,0,0,1,.518-.486c.57-.009,1.14,0,1.709,0h3.577" transform="translate(-115.571 -250.345)" fill="#d5ad5d" />
            <path id="Tracé_20573" data-name="Tracé 20573" d="M130.09,233.068q-2.591,0-5.181,0a.528.528,0,0,1-.148-1.045,1.354,1.354,0,0,1,.21-.007h10.284c.382,0,.6.153.644.441a.52.52,0,0,1-.575.611c-1.078,0-2.157,0-3.235,0h-2" transform="translate(-115.564 -215.693)" fill="#d5ad5d" />
            <path id="Tracé_20574" data-name="Tracé 20574" d="M82.964,99.007c0-.385,0-.77,0-1.156,0-.324.12-.436.448-.441.219,0,.438,0,.657,0a1.529,1.529,0,0,1,1.575,1.921,1.445,1.445,0,0,1-1.52,1.272c-.228,0-.455,0-.683,0-.362,0-.474-.111-.477-.466,0-.376,0-.753,0-1.129m.717,0c0,.254,0,.509,0,.763,0,.055-.026.119.056.155a1.038,1.038,0,0,0,.982-.3,1.277,1.277,0,0,0,.035-1.205c-.232-.366-.6-.338-.963-.335-.124,0-.113.079-.113.159q0,.382,0,.763" transform="translate(-77.127 -90.555)" fill="#d5ad5d" />
            <path id="Tracé_20575" data-name="Tracé 20575" d="M45.112,98.927c0-.385,0-.77,0-1.156,0-.307.126-.44.428-.438a5.63,5.63,0,0,1,1.074.031,1.028,1.028,0,0,1,.845,1.125.991.991,0,0,1-1,1c-.2.009-.469-.087-.575.044s-.033.383-.039.583c-.009.286-.12.418-.355.422s-.37-.132-.374-.43c0-.394,0-.788,0-1.182m.731-.5v.314c0,.054,0,.11.076.106a1.294,1.294,0,0,0,.541-.05.406.406,0,0,0,.236-.451.373.373,0,0,0-.33-.349c-.478-.1-.523-.063-.523.43" transform="translate(-41.937 -90.477)" fill="#d5ad5d" />
            <path id="Tracé_20576" data-name="Tracé 20576" d="M126.578,98.977c0-.394,0-.789,0-1.183,0-.276.125-.406.4-.413q.526-.013,1.051,0c.246.006.386.139.385.34s-.137.325-.388.332a3.479,3.479,0,0,1-.525,0c-.242-.031-.2.13-.19.259s-.108.344.187.319a3.952,3.952,0,0,1,.5,0,.3.3,0,0,1,.317.314.3.3,0,0,1-.291.339,1.652,1.652,0,0,1-.21.012c-.507.006-.507.006-.507.524,0,.14.008.281-.006.42a.335.335,0,0,1-.375.348.33.33,0,0,1-.345-.375c0-.412,0-.824,0-1.236" transform="translate(-117.672 -90.525)" fill="#d5ad5d" />
            <path id="Tracé_20577" data-name="Tracé 20577" d="M121.16,60.373a.521.521,0,0,1-.52.54.54.54,0,0,1-.527-.514.531.531,0,0,1,.527-.536.514.514,0,0,1,.519.51" transform="translate(-111.663 -55.652)" fill="#d5ad5d" />
        </g>
    </svg>


);



export const Walletcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="27.424" height="27.644" viewBox="0 0 27.424 27.644">
        <defs>
            <clipPath id="clip-path">
                <rect id="Rectangle_531" data-name="Rectangle 531" width="27.424" height="27.644" fill="#fff" />
            </clipPath>
        </defs>
        <g id="Groupe_3240" data-name="Groupe 3240" opacity="0.87">
            <g id="Groupe_3239" data-name="Groupe 3239" clip-path="url(#clip-path)">
                <path id="Tracé_20578" data-name="Tracé 20578" d="M18.489,18.632q-1.113,1.1-2.225,2.206a2.343,2.343,0,0,1-3.555,0,1.183,1.183,0,0,1-.132-.135c-.117-.162-.222-.177-.351-.006a.609.609,0,0,1-.345.247.536.536,0,0,1-.568-.847,1.875,1.875,0,0,1,.185-.2q1.663-1.661,3.327-3.323a1.072,1.072,0,1,0-1.506-1.523c-.717.709-1.436,1.417-2.139,2.141a.973.973,0,0,1-.786.33,3.631,3.631,0,0,0-2.542.8A3.381,3.381,0,0,0,6.53,21.1a1.72,1.72,0,0,1-.592,1.385c-1.646,1.6-3.259,3.237-4.886,4.859a1.567,1.567,0,0,1-.221.2.522.522,0,0,1-.688-.077.541.541,0,0,1-.033-.692,2.259,2.259,0,0,1,.2-.216q2.476-2.473,4.955-4.942a.486.486,0,0,0,.167-.371,4.655,4.655,0,0,1,3.092-4.524c-.006-.1-.1-.147-.156-.208-.623-.625-1.25-1.247-1.873-1.872a2.32,2.32,0,0,1,0-3.47q1.653-1.652,3.308-3.3a1.672,1.672,0,0,1,.22-.2.52.52,0,0,1,.689.067.531.531,0,0,1,.046.692,1.973,1.973,0,0,1-.2.217l-3.308,3.3a1.244,1.244,0,0,0,0,1.921l2.409,2.405a.616.616,0,0,0,.915-.012c.689-.686,1.364-1.389,2.072-2.055a2.142,2.142,0,0,1,3.513.937,2.1,2.1,0,0,1-.575,2.2q-1.048,1.053-2.1,2.1c-.322.321-.322.322.009.651a2.568,2.568,0,0,0,.24.218,1.221,1.221,0,0,0,1.525-.019,3.4,3.4,0,0,0,.255-.241L25.826,9.764a1.265,1.265,0,0,0,.006-2.037L19.673,1.581a1.274,1.274,0,0,0-2.04,0l-3.309,3.3a2.146,2.146,0,0,1-.237.22.542.542,0,0,1-.693-.053.533.533,0,0,1-.06-.69,1.593,1.593,0,0,1,.182-.2c.956-.955,1.918-1.9,2.866-2.866A4.026,4.026,0,0,1,18.249,0h.757a3.756,3.756,0,0,1,1.9,1.276Q23.743,4.128,26.6,6.964a2.333,2.333,0,0,1-.007,3.567q-2.953,2.949-5.91,5.894a1.3,1.3,0,0,0-.318.488q-1.025,2.79-2.059,5.576A4.793,4.793,0,0,1,14.972,25.6q-1.878.54-3.761,1.062a1.138,1.138,0,0,0-.592.34,5.632,5.632,0,0,1-.5.494.536.536,0,0,1-.753-.762c.271-.289.558-.562.838-.842a.873.873,0,0,1,.414-.183c1.391-.395,2.786-.777,4.173-1.185a3.583,3.583,0,0,0,2.438-2.259c.451-1.181.875-2.371,1.311-3.558.032-.035.06-.083.023-.116s-.056.02-.075.048" transform="translate(0)" fill="#fff" />
                <path id="Tracé_20579" data-name="Tracé 20579" d="M235.226,57.918a1.916,1.916,0,0,1-.02-3.831,1.916,1.916,0,1,1,.02,3.831m0-1.081a.844.844,0,0,0,.841-.839.861.861,0,0,0-.855-.832.843.843,0,0,0-.819.833.824.824,0,0,0,.833.838" transform="translate(-216.474 -50.184)" fill="#fff" />
                <path id="Tracé_20580" data-name="Tracé 20580" d="M240.813,118.42a.524.524,0,0,1,.187-.372q2.77-2.765,5.542-5.529a.54.54,0,1,1,.774.748q-2.775,2.779-5.558,5.551a.54.54,0,0,1-.944-.4" transform="translate(-223.437 -104.213)" fill="#fff" />
                <path id="Tracé_20581" data-name="Tracé 20581" d="M134.178,142.6a.532.532,0,0,1-.518-.292.472.472,0,0,1,.062-.577c.622-.636,1.25-1.266,1.89-1.884a.513.513,0,0,1,.728.049.538.538,0,0,1-.021.753c-.419.432-.85.853-1.276,1.279-.165.165-.33.331-.5.494a.522.522,0,0,1-.369.178" transform="translate(-123.951 -129.635)" fill="#fff" />
                <path id="Tracé_20582" data-name="Tracé 20582" d="M160.088,82.135a.534.534,0,0,1-.533-.527.549.549,0,0,1,.546-.548.539.539,0,0,1,.529.56.526.526,0,0,1-.541.514" transform="translate(-148.043 -75.211)" fill="#fff" />
            </g>
        </g>
    </svg>


);
